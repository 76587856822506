.container {
  width: 100%;
  padding: 12px 24px;
  border-radius: 16px;
  background: var(--palette-default);
  border: 1px solid var(--palette-border-primary);
  box-shadow: var(--palette-box-shadow);
  overflow: auto;

  @media screen and (min-width: 768px) {
    grid-column: 1/8;
  }

  @media screen and (min-width: 1200px) {
    grid-column: 1/8;
  }
}

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 24px;

  @media screen and (min-width: 1200px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.titleWrapper {
  display: flex;
  flex-direction: column;
}

.subtitle {
  font-size: 12px;
  color: var(--palette-text-secondary);
}

.label {
  font-size: 16px;
  font-weight: 500;
}

.tabs {
  margin: 12px 0;

  @media screen and (min-width: 1200px) {
    margin: 0;
  }
}

.headerRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.searchWrapper {
  max-width: 300px;
}

.filtersWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;

  @media screen and (min-width: 1000px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 0;
  }
}

.search {
  div {
    height: 32px !important;

    input {
      height: 32px !important;
      border: 1px solid rgba(0, 0, 0, 0.1) !important;
    }
  }
}

.content {
  width: 100%;
  margin-top: 6px;
}

.pagination {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 24px;
}

.table {
  margin-top: 12px;
  border-radius: 8px;
}

.tableContent {
  row-gap: 0 !important;
  min-height: unset !important;
}

.wrapper {
  height: 28px !important;
  width: 208px !important;
}

.input {
  height: 28px !important;
  border-color: rgba(0, 0, 0, 0.1) !important;
}

.row {
  border-top: 0.5px solid rgba(0, 0, 0, 0.1);
  border-radius: 0 !important;
  max-height: 46px !important;
}

.name {
  font-weight: 600;

  span {
    color: var(--palette-text-secondary);
    font-size: 10px;
    font-weight: 400 !important;
  }
}

.finished {
  svg {
    color: #50c878;
  }

  span {
    color: #50c878;
    font-weight: 500;
    font-size: 12px;
  }
}

.completed {
  svg {
    color: var(--palette-success);
  }

  span {
    color: var(--palette-success);
    font-weight: 500;
    font-size: 12px;
  }
}

.failed {
  svg {
    color: var(--palette-error);
  }

  span {
    color: var(--palette-error);
    font-weight: 500;
    font-size: 12px;
  }
}

.pending {
  svg {
    color: var(--palette-grey-active);
  }

  span {
    color: var(--palette-grey-active);
    font-weight: 500;
    font-size: 12px;
  }
}
