.projectDescription {
  margin: 0 0 8px 0;
  color: var(--palette-text-secondary);
  font-size: 12px;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-bottom: 12px;
  flex-wrap: wrap;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  margin-bottom: 12px;
}

.button {
  background-color: var(--palette-grey-lightly);
  color: var(--palette-text-secondary);
  padding: 4px 6px;
  font-size: 11px;
  border: 1px solid var(--palette-secondary);
  cursor: pointer;
  border-radius: 6px;
  transition: 0.3s ease;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  &:hover {
    background-color: var(--palette-grey-table-bg);
  }
}

.buttonActive {
  color: var(--palette-text-primary);
  border: 1px solid var(--palette-secondary-active);

  &:hover {
    background-color: var(--palette-grey-table-bg);
  }
}

.row {
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-bottom: 12px;
  flex-wrap: wrap;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  margin-bottom: 12px;
}

.button {
  background-color: var(--palette-grey-lightly);
  color: var(--palette-text-secondary);
  padding: 4px 6px;
  font-size: 11px;
  border: 1px solid var(--palette-secondary);
  cursor: pointer;
  border-radius: 6px;
  transition: 0.3s ease;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  &:hover {
    background-color: var(--palette-grey-table-bg);
  }
}

.buttonActive {
  color: var(--palette-text-primary);
  border: 1px solid var(--palette-secondary-active);

  &:hover {
    background-color: var(--palette-grey-table-bg);
  }
}

.labelWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.buttonWrapper {
  margin-bottom: 12px;
}
